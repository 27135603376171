:root {
  --primaryColor: #005284;
  --secondaryColor: #f7931e;
  --backgroundColor: #fefefe;
  --surfaceColor: #e1e3e6;
  --onPrimaryColor: #fefefe;
  --onSecondaryColor: #3a3a3a;
  --onBackgroundColor: #3a3a3a;
  --onSurfaceColor: #3a3a3a;

  --lightColor: #fefefe;
  --darkColor: #3a3a3a;

  --lowIndex: 1;
  --midIndex: 10;
  --highIndex: 100;
  --ultraHighIndex: 1000;
  --maxIndex: 10000;

  --sideBarWidth: 300px;
  --topBarHeight: 60px;

  /* shadows */
  --outerShadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  --midOuterShadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
  --borderOuterShadow:rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  --innerShadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  --midInnerShadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

/*---------------- Fonts ---------------*/
@font-face {
  font-family: "FontRegular";
  src: local("FontRegular"), url("./fonts/quicksand/regular.ttf") format("truetype");
}

@font-face {
  font-family: "FontBold";
  src: local("FontBold"), url("./fonts/quicksand/bold.ttf") format("truetype");
}

@font-face {
  font-family: "FontLight";
  src: local("FontLight"), url("./fonts/quicksand/light.ttf") format("truetype");
}

@font-face {
  font-family: "FontSemiBold";
  src: local("FontItalic"), url("./fonts/quicksand/semi-bold.ttf") format("truetype");
}

@font-face {
  font-family: "FontMedium";
  src: local("FontItalic"), url("./fonts/quicksand/medium.ttf") format("truetype");
}

.font-bold{
  font-family: "FontBold" !important;
}

.font-regular{
  font-family: "FontRegular" !important;
}

.font-light{
  font-family: "FontLight" !important;
}

.font-medium{
  font-family: "FontMedium" !important;
}

.font-semi-bold{
  font-family: "FontSemiBold" !important;
}

/*********** FONTS END Here *******************/

html,
body {
  background: var(--backgroundColor);
  color: var(--onBackgroundColor);
  font-family: "FontRegular";
  width: 100%;
  height: 100%;
  font-size: 1em;
  scroll-behavior: smooth;
}

#root {
  width: 100%;
  height: 100%;
}

* {
  word-wrap: break-word;
}


/********* WIDGETS *********/
.Paper {
  position: fixed;
  z-index: var(--highIndex);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
}

a {
  text-decoration: none;
}

.mobileOnly {
  display: none;
}

.desktopOnly {
  display: block;
}

@media (max-width: 991.98px) {

  .mobileOnly {
    display: block;
  }

  .desktopOnly {
    display: none;
  }
}

.mShadow1 {
  box-shadow: var(--outerShadow);
}

.mShadow2 {
  box-shadow: var(--midOuterShadow);
}

.mShadow3 {
  box-shadow: var(--borderOuterShadow);
}

.mShadow4 {
  box-shadow: var(--innerShadow);
}

.mShadow5 {
  box-shadow: var(--midInnerShadow);
}

.mSectionHeader {
  margin-top: var(--topBarHeight);
  margin-bottom: calc(0.1 * var(--topBarHeight));
  font-family: "FontLight";
}

.text-primary {
  color: var(--primaryColor) !important;
}

.text-secondary {
  color: var(--secondaryColor) !important;
}

.text-light {
  color: var(--lightColor) !important;
}

.text-dark {
  color: var(--darkColor) !important;
}

.bg-primary {
  color: var(--onPrimaryColor) !important;
  background: var(--primaryColor) !important;
}

.bg-secondary {
  color: var(--onSecondaryColor) !important;
  background: var(--secondaryColor) !important;
}

.bg-surface {
  color: var(--onSurfaceColor) !important;
  background: var(--surfaceColor) !important;
}

.bg-background {
  color: var(--onBackgroundColor) !important;
  background: var(--backgroundColor) !important;
}


.bg-light {
  color: var(--darkColor) !important;
  background: var(--lightColor) !important;
}

.bg-dark {
  color: var(--lightColor) !important;
  background: var(--darkColor) !important;
}

.mNoMargin {
  margin: 0px !important;
}

.mNoPadding {
  padding: 0px !important;
}

.mBorder10 {
  border-radius: 10px !important;
}

.mBorder15 {
  border-radius: 15px !important;
}

.mBorder20 {
  border-radius: 20px !important;
}


.mNoShadow {
  box-shadow: none !important;
}

.mNoBg {
  background: none !important;
}

.mHorizontalScroll {
  overflow-x: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.mHorizontalScroll::-webkit-scrollbar {
  display: none;
}

.mSupportLoading {
  min-width: 50px;
  min-height: 50px;
  position: relative;
}

.mOneLineText {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.btn-rounded {
  border-radius: 30px;
}

.btn-block {
  display: block;
  width: 100%;
}

input,
textarea {
  background: inherit !important;
  border: 1px solid var(--primaryColor) !important;
}

input:focus,
textarea:focus {
  background: inherit !important;
  outline: none !important;
  box-shadow: none !important;
  border: 2px solid var(--onBackgroundColor) !important;
}

.form-group {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
}

label {
  font-family: 'FontLight';
  font-size: 16px;
  margin-bottom: 10px;
}

a {
  cursor: pointer;
}


/**********************/

/* MainLoader */
.MainLoader {
  position: fixed;
  z-index: var(--maxIndex);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
}

/* Loader */
.Loader {
  width: 40px;
  height: 40px;
  position: absolute;
  border-radius: 20px;
  background: none;
  border: 4px solid var(--secondaryColor) !important;
  border-left: 4px solid var(--backgroundColor) !important;
  top: 50%;
  z-index: inherit;
  left: 50%;
  margin-left: -20px;
  margin-top: -20px;
  animation-name: "loading";
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  -webkit-animation-name: "loading";
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
}

@keyframes loading {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.TopBar {
  position: fixed;
  height: var(--topBarHeight);
  width: 100%;
  background: var(--backgroundColor);
  /* box-shadow: var(--borderOuterShadow); activate this during scrolls*/
  color: var(--onBackgroundColor);
  z-index: var(--highIndex);
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.TopBar >
.container {
  height: 100%;
}

.TopBar 
.mMenuItem {
  font-family: "FontBold";
  color: var(--onSurfaceColor);
  flex-shrink: 0;
}

/******* MainBody **********/
.MainBody {
  width: 100%;
  padding: 0;
  margin: 0;
  padding-top: var(--topBarHeight);
  padding-bottom: var(--topBarHeight);
}


/** Banner **/
.Banner {
  width: 100%;
  min-height: 130vh;
  margin: 0;
  padding: 0;
  background-color: var(--backgroundColor);
  background: var(--backgroundColor);
  background-image: url(./assets/run_bg_1.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
}

/** Section **/
.Section {
  width: 100%;
  margin: 0;
  padding: 0;
  padding-top: calc(1 * var(--topBarHeight));
  padding-bottom: calc(1 * var(--topBarHeight));
}

/** EventThumbnail **/
.EventThumbnail {
  width: 250px;
  height: 300px;
  background: none;
  padding: 10px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  flex-shrink: 0;
 
}

.EventThumbnail[data-support-col="true"] {
  width: 100%;
}


.EventThumbnail >
.mCover {
  width: 100%;
  height: calc(100% - (1.5 * var(--topBarHeight)));
  margin-top: calc(0.5 * var(--topBarHeight));
  background: none;
  overflow: hidden;
  border-radius: 20px;
  padding: 0;
  z-index: 1;
  cursor: pointer;
}

.EventThumbnail >
.mCover >
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.EventThumbnail >
.mDate {
  position: absolute;
  width: calc(var(--topBarHeight) * 2);
  height: calc(var(--topBarHeight) * 0.5);
  border-radius: 20px;
  background: var(--surfaceColor);
  color: var(--onSurfaceColor);
  top: calc((var(--topBarHeight) * 0.25) + 10px);
  left: 0px;
  z-index: 2;
  box-shadow: var(--outerShadow);
  padding: 0;
  overflow: hidden;
  padding-top: 5px;
  font-family: "FontBold";
  font-size: 12px;
}

.EventThumbnail > 
.mTitle {
  width: 100%;
  height: var(--topBarHeight);
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
  white-space: nowrap;
  background: none;
  font-size: 20px;
  font-family: "FontMedium";
}

/***** SideBar ******/
.SideBar {
  width: var(--sideBarWidth);
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background: var(--primaryColor);
  overflow: auto;
  z-index: var(--ultraHighIndex);
  transition: all .3s ease-out;
}


.SideBar >
.mContainer {
  width: 85%;
  margin: 0 auto;
  background: inherit;
  height: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}



.SideBar >
.mContainer >
.mItem {
  width: 100%;
  height: calc(var(--topBarHeight) * 0.6);
  margin-bottom: 10px;
  background: none;
  color: var(--onPrimaryColor);
  padding: 10px;
  overflow: hidden;
  cursor: pointer;
}

.SideBar >
.mContainer >
.mItem[data-selected='true'],
.SideBar >
.mContainer >
.mItem:hover{
  background: var(--surfaceColor);
  color: var(--onSurfaceColor);
}



.SideBar >
.mContainer >
.mItem >
.mIcon {
  font-size: 30px;
  align-self: center;
  margin: 0px;
  padding: 0px;
  flex-shrink: 0;
}


.SideBar >
.mContainer >
.mItem >
.mTitle {
  font-size: 16px;
  align-self: center;
  margin: 0px;
  padding: 0px;
  padding-left: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-grow: 1;
  font-family: 'FontRegular';
}

.SideBar
.mobileOnly {
  display: none;
}

@media (min-width: 992px) {
  .SideBar {
    display: none;
  }
}

@media (max-width: 991.98px) {
  
  .SideBar[data-closed='true'] {
    left: calc(-1 * var(--sideBarWidth));
  }
  
  .SideBar[data-closed='false'] {
    left: 0 !important;
  }

  .SideBar
  .mobileOnly {
    display: block;
  }
}

/*****sidebar end **/